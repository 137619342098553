//BosWorth imports
import wrigley01 from "../src/Site_Projects/03-Bosworth/Wrigleyville-01-desktop.webp";
import wrigley02 from "../src/Site_Projects/03-Bosworth/Wrigleyville-02-desktop.webp";
import wrigley03 from "./Site_Projects/03-Bosworth/Wrigleyville-03-desktop.webp";
import wrigley04 from "./Site_Projects/03-Bosworth/Wrigleyville-04-desktop.webp";
import wrigley05 from "./Site_Projects/03-Bosworth/Wrigleyville-05-desktop.webp";
import wrigley06 from "./Site_Projects/03-Bosworth/Wrigleyville-06-desktop.webp";
import wrigley07 from "./Site_Projects/03-Bosworth/Wrigleyville-07-desktop.webp";
import wrigley08 from "./Site_Projects/03-Bosworth/Wrigleyville-08-desktop.webp";
import wrigley09 from "./Site_Projects/03-Bosworth/Wrigleyville-09-desktop.webp";
import wrigley10 from "./Site_Projects/03-Bosworth/Wrigleyville-10-desktop.webp";
import wrigley11 from "./Site_Projects/03-Bosworth/Wrigleyville-11-desktop.webp";
import wrigley12 from "./Site_Projects/03-Bosworth/Wrigleyville-12-desktop.webp";
import wrigley13 from "./Site_Projects/03-Bosworth/Wrigleyville-13-desktop.webp";
import wrigley14 from "./Site_Projects/03-Bosworth/Wrigleyville-14-desktop.webp";
import wrigley15 from "./Site_Projects/03-Bosworth/Wrigleyville-15-desktop.webp";
import wrigley16 from "./Site_Projects/03-Bosworth/Wrigleyville-16-desktop.webp";
import wrigley17 from "./Site_Projects/03-Bosworth/Wrigleyville-17-desktop.webp";
import wrigley18 from "./Site_Projects/03-Bosworth/Wrigleyville-18-desktop.webp";
import wrigley19 from "./Site_Projects/03-Bosworth/Wrigleyville-19-desktop.webp";

//Caffe-Streets imports
import caffe01 from "./Site_Projects/18-Caffe-Streets/Caffe-S-01-desktop.webp";
import caffe02 from "./Site_Projects/18-Caffe-Streets/Caffe-S-02-desktop.webp";
import caffe03 from "./Site_Projects/18-Caffe-Streets/Caffe-S-03-desktop.webp";
import caffe04 from "./Site_Projects/18-Caffe-Streets/Caffe-S-04-desktop.webp";
import caffe05 from "./Site_Projects/18-Caffe-Streets/Caffe-S-05-desktop.webp";
import caffe06 from "./Site_Projects/18-Caffe-Streets/Caffe-S-06-desktop.webp";
import caffe07 from "./Site_Projects/18-Caffe-Streets/Caffe-S-07-desktop.webp";
import caffe08 from "./Site_Projects/18-Caffe-Streets/Caffe-S-08-desktop.webp";
import caffe09 from "./Site_Projects/18-Caffe-Streets/Caffe-S-09-desktop.webp";
import caffe10 from "./Site_Projects/18-Caffe-Streets/Caffe-S-10-desktop.webp";
import caffe11 from "./Site_Projects/18-Caffe-Streets/Caffe-S-11-desktop.webp";
import caffe12 from "./Site_Projects/18-Caffe-Streets/Caffe-S-12-desktop.webp";

//division imports
import division01 from "./Site_Projects/02-Division/Division-01-desktop.webp";
import division02 from "./Site_Projects/02-Division/Division-02-desktop.webp";
import division03 from "./Site_Projects/02-Division/Division-03-desktop.webp";
import division04 from "./Site_Projects/02-Division/Division-04-desktop.webp";
import division05 from "./Site_Projects/02-Division/Division-05-desktop.webp";
import division06 from "./Site_Projects/02-Division/Division-06-desktop.webp";
import division07 from "./Site_Projects/02-Division/Division-07-desktop.webp";
import division08 from "./Site_Projects/02-Division/Division-08-desktop.webp";
import division09 from "./Site_Projects/02-Division/Division-09-desktop.webp";
// import division10 from "./Site_Projects/02-Division/Division-10.jpg";

//fairbanks imports
import fairbanks01 from "./Site_Projects/06-Fairbanks/Fairbanks-01-desktop.webp";
import fairbanks02 from "./Site_Projects/06-Fairbanks/Fairbanks-02-desktop.webp";
import fairbanks03 from "./Site_Projects/06-Fairbanks/Fairbanks-03-desktop.webp";
import fairbanks04 from "./Site_Projects/06-Fairbanks/Fairbanks-04-desktop.webp";
import fairbanks05 from "./Site_Projects/06-Fairbanks/Fairbanks-05-desktop.webp";
import fairbanks06 from "./Site_Projects/06-Fairbanks/Fairbanks-06-desktop.webp";
import fairbanks07 from "./Site_Projects/06-Fairbanks/Fairbanks-07-desktop.webp";
import fairbanks08 from "./Site_Projects/06-Fairbanks/Fairbanks-08-desktop.webp";
import fairbanks09 from "./Site_Projects/06-Fairbanks/Fairbanks-09-desktop.webp";
import fairbanks10 from "./Site_Projects/06-Fairbanks/Fairbanks-10-desktop.webp";
import fairbanks11 from "./Site_Projects/06-Fairbanks/Fairbanks-11-desktop.webp";
import fairbanks12 from "./Site_Projects/06-Fairbanks/Fairbanks-12-desktop.webp";
import fairbanks13 from "./Site_Projects/06-Fairbanks/Fairbanks-13-desktop.webp";
// import fairbanks14 from "./Site_Projects/06-Fairbanks/Fairbanks-14.webp";

//Hermitage imports
import hermitage01 from "./Site_Projects/16-Hermitage/Hermitage-01-desktop.webp";
import hermitage02 from "./Site_Projects/16-Hermitage/Hermitage-02-desktop.webp";
import hermitage03 from "./Site_Projects/16-Hermitage/Hermitage-03-desktop.webp";
import hermitage04 from "./Site_Projects/16-Hermitage/Hermitage-04-desktop.webp";
import hermitage05 from "./Site_Projects/16-Hermitage/Hermitage-05-desktop.webp";
import hermitage06 from "./Site_Projects/16-Hermitage/Hermitage-06-desktop.webp";
import hermitage07 from "./Site_Projects/16-Hermitage/Hermitage-07-desktop.webp";
import hermitage08 from "./Site_Projects/16-Hermitage/Hermitage-08-desktop.webp";
import hermitage09 from "./Site_Projects/16-Hermitage/Hermitage-09-desktop.webp";
import hermitage10 from "./Site_Projects/16-Hermitage/Hermitage-10-desktop.webp";
import hermitage11 from "./Site_Projects/16-Hermitage/Hermitage-11-desktop.webp";
import hermitage12 from "./Site_Projects/16-Hermitage/Hermitage-12-desktop.webp";

//Hoffman Estates Imports
import hoffman01 from "./Site_Projects/21-Hoffman-States-Clubhouse/Hoffman-01-desktop.webp";
import hoffman02 from "./Site_Projects/21-Hoffman-States-Clubhouse/Hoffman-02-desktop.webp";
import hoffman03 from "./Site_Projects/21-Hoffman-States-Clubhouse/Hoffman-03-desktop.webp";
import hoffman04 from "./Site_Projects/21-Hoffman-States-Clubhouse/Hoffman-04-desktop.webp";
import hoffman05 from "./Site_Projects/21-Hoffman-States-Clubhouse/Hoffman-05-desktop.webp";
import hoffman06 from "./Site_Projects/21-Hoffman-States-Clubhouse/Hoffman-06-desktop.webp";
import hoffman07 from "./Site_Projects/21-Hoffman-States-Clubhouse/Hoffman-07-desktop.webp";
import hoffman08 from "./Site_Projects/21-Hoffman-States-Clubhouse/Hoffman-08-desktop.webp";
import hoffman09 from "./Site_Projects/21-Hoffman-States-Clubhouse/Hoffman-09-desktop.webp";
import hoffman10 from "./Site_Projects/21-Hoffman-States-Clubhouse/Hoffman-10-desktop.webp";
import hoffman11 from "./Site_Projects/21-Hoffman-States-Clubhouse/Hoffman-11-desktop.webp";

//Kimbark imports
import kimbark01 from "./Site_Projects/04-Kimbark/Kimbark-01-desktop.webp";
import kimbark02 from "./Site_Projects/04-Kimbark/Kimbark-02-desktop.webp";
import kimbark03 from "./Site_Projects/04-Kimbark/Kimbark-03-desktop.webp";
import kimbark04 from "./Site_Projects/04-Kimbark/Kimbark-04-desktop.webp";
import kimbark05 from "./Site_Projects/04-Kimbark/Kimbark-05-desktop.webp";
import kimbark06 from "./Site_Projects/04-Kimbark/Kimbark-06-desktop.webp";
import kimbark07 from "./Site_Projects/04-Kimbark/Kimbark-07-desktop.webp";
import kimbark08 from "./Site_Projects/04-Kimbark/Kimbark-08-desktop.webp";
import kimbark09 from "./Site_Projects/04-Kimbark/Kimbark-09-desktop.webp";
import kimbark10 from "./Site_Projects/04-Kimbark/Kimbark-10-desktop.webp";
import kimbark11 from "./Site_Projects/04-Kimbark/Kimbark-11-desktop.webp";
import kimbark12 from "./Site_Projects/04-Kimbark/Kimbark-12-desktop.webp";
import kimbark13 from "./Site_Projects/04-Kimbark/Kimbark-13-desktop.webp";

//Lake-Meadows imports
import lakem01 from "./Site_Projects/14-Lake-Meadows/Lake-01-desktop.webp";
import lakem02 from "./Site_Projects/14-Lake-Meadows/Lake-02-desktop.webp";
import lakem03 from "./Site_Projects/14-Lake-Meadows/Lake-03-desktop.webp";
import lakem04 from "./Site_Projects/14-Lake-Meadows/Lake-04-desktop.webp";
import lakem05 from "./Site_Projects/14-Lake-Meadows/Lake-05-desktop.webp";
import lakem06 from "./Site_Projects/14-Lake-Meadows/Lake-06-desktop.webp";
// import lakem07 from "./Site_Projects/14-Lake-Meadows/Lake-07-desktop.webp";
import lakem08 from "./Site_Projects/14-Lake-Meadows/Lake-08-desktop.webp";
import lakem09 from "./Site_Projects/14-Lake-Meadows/Lake-09-desktop.webp";
import lakem10 from "./Site_Projects/14-Lake-Meadows/Lake-10-desktop.webp";

//Leavitt-House I imports
import leavittI01 from "./Site_Projects/10-Leavitt-I/Leavitt3-01-desktop.webp";
import leavittI02 from "./Site_Projects/10-Leavitt-I/Leavitt3-02-desktop.webp";
import leavittI03 from "./Site_Projects/10-Leavitt-I/Leavitt3-03-desktop.webp";
import leavittI04 from "./Site_Projects/10-Leavitt-I/Leavitt3-04-desktop.webp";
import leavittI05 from "./Site_Projects/10-Leavitt-I/Leavitt3-05-desktop.webp";
import leavittI06 from "./Site_Projects/10-Leavitt-I/Leavitt3-06-desktop.webp";
import leavittI07 from "./Site_Projects/10-Leavitt-I/Leavitt3-07-desktop.webp";
import leavittI08 from "./Site_Projects/10-Leavitt-I/Leavitt3-08-desktop.webp";
import leavittI09 from "./Site_Projects/10-Leavitt-I/Leavitt3-09-desktop.webp";
import leavittI10 from "./Site_Projects/10-Leavitt-I/Leavitt3-10-desktop.webp";
import leavittI11 from "./Site_Projects/10-Leavitt-I/Leavitt3-11.MP4";
import leavittI12 from "./Site_Projects/10-Leavitt-I/Leavitt3-12-desktop.webp";
import leavittI13 from "./Site_Projects/10-Leavitt-I/Leavitt3-13-desktop.webp";
import leavittI14 from "./Site_Projects/10-Leavitt-I/Leavitt3-14-desktop.webp";
import leavittI15 from "./Site_Projects/10-Leavitt-I/Leavitt3-15-desktop.webp";
import leavittI16 from "./Site_Projects/10-Leavitt-I/Leavitt3-16-desktop.webp";
import leavittI17 from "./Site_Projects/10-Leavitt-I/Leavitt3-17-desktop.webp";

// Leavitt-House II imports
import leavittII01 from "./Site_Projects/20-Leavitt-House-II/Leavitt-House-I-01-desktop.webp";
import leavittII02 from "./Site_Projects/20-Leavitt-House-II/Leavitt-House-I-02-desktop.webp";
import leavittII03 from "./Site_Projects/20-Leavitt-House-II/Leavitt-House-I-03-desktop.webp";
import leavittII04 from "./Site_Projects/20-Leavitt-House-II/Leavitt-House-I-04-desktop.webp";
import leavittII05 from "./Site_Projects/20-Leavitt-House-II/Leavitt-House-I-05-desktop.webp";
import leavittII06 from "./Site_Projects/20-Leavitt-House-II/Leavitt-House-I-06-desktop.webp";
import leavittII07 from "./Site_Projects/20-Leavitt-House-II/Leavitt-House-I-07-desktop.webp";
import leavittII08 from "./Site_Projects/20-Leavitt-House-II/Leavitt-House-I-08-desktop.webp";
import leavittII09 from "./Site_Projects/20-Leavitt-House-II/Leavitt-House-I-09-desktop.webp";
import leavittII10 from "./Site_Projects/20-Leavitt-House-II/Leavitt-House-I-10-desktop.webp";
import leavittII11 from "./Site_Projects/20-Leavitt-House-II/Leavitt-House-I-11-desktop.webp";
import leavittII12 from "./Site_Projects/20-Leavitt-House-II/Leavitt-House-I-12-desktop.webp";
import leavittII13 from "./Site_Projects/20-Leavitt-House-II/Leavitt-House-I-13-desktop.webp";
import leavittII14 from "./Site_Projects/20-Leavitt-House-II/Leavitt-House-I-14-desktop.webp";
import leavittII15 from "./Site_Projects/20-Leavitt-House-II/Leavitt-House-I-15-desktop.webp";
import leavittII16 from "./Site_Projects/20-Leavitt-House-II/Leavitt-House-I-16-desktop.webp";

//Maria's imports
import marias01 from "./Site_Projects/11-Maria_s/Maria_s-01-desktop.webp";
import marias02 from "./Site_Projects/11-Maria_s/Maria_s-02-desktop.webp";
import marias03 from "./Site_Projects/11-Maria_s/Maria_s-03-desktop.webp";
import marias04 from "./Site_Projects/11-Maria_s/Maria_s-04-desktop.webp";
import marias05 from "./Site_Projects/11-Maria_s/Maria_s-05-desktop.webp";
import marias06 from "./Site_Projects/11-Maria_s/Maria_s-06-desktop.webp";
import marias07 from "./Site_Projects/11-Maria_s/Maria_s-07-desktop.webp";
import marias08 from "./Site_Projects/11-Maria_s/Maria_s-08-desktop.webp";
import marias09 from "./Site_Projects/11-Maria_s/Maria_s-09-desktop.webp";
import marias10 from "./Site_Projects/11-Maria_s/Maria_s-10-desktop.webp";
import marias11 from "./Site_Projects/11-Maria_s/Maria_s-11-desktop.webp";

//Marz imports
import marz01 from "./Site_Projects/12-Marz-Brewing/Marz-01-desktop.webp";
import marz02 from "./Site_Projects/12-Marz-Brewing/Marz-02-desktop.webp";
import marz03 from "./Site_Projects/12-Marz-Brewing/Marz-03-desktop.webp";
import marz04 from "./Site_Projects/12-Marz-Brewing/Marz-04-desktop.webp";
import marz05 from "./Site_Projects/12-Marz-Brewing/Marz-05-desktop.webp";
import marz06 from "./Site_Projects/12-Marz-Brewing/Marz-06-desktop.webp";
import marz07 from "./Site_Projects/12-Marz-Brewing/Marz-07-desktop.webp";
import marz08 from "./Site_Projects/12-Marz-Brewing/Marz-08-desktop.webp";
import marz09 from "./Site_Projects/12-Marz-Brewing/Marz-09-desktop.webp";
import marz10 from "./Site_Projects/12-Marz-Brewing/Marz-10-desktop.webp";
import marz11 from "./Site_Projects/12-Marz-Brewing/Marz-11-desktop.webp";
import marz12 from "./Site_Projects/12-Marz-Brewing/Marz-12-desktop.webp";
import marz13 from "./Site_Projects/12-Marz-Brewing/Marz-13-desktop.webp";
import marz14 from "./Site_Projects/12-Marz-Brewing/Marz-14-desktop.webp";
import marz15 from "./Site_Projects/12-Marz-Brewing/Marz-15-desktop.webp";
import marz16 from "./Site_Projects/12-Marz-Brewing/Marz-16-desktop.webp";
import marz17 from "./Site_Projects/12-Marz-Brewing/Marz-17-desktop.webp";
import marz18 from "./Site_Projects/12-Marz-Brewing/Marz-18-desktop.webp";

//milwaukee imports
import milwaukee01 from "./Site_Projects/07-Milwaukee/Milwaukee-01-desktop.webp";
import milwaukee02 from "./Site_Projects/07-Milwaukee/Milwaukee-02.mp4";
import milwaukee03 from "./Site_Projects/07-Milwaukee/Milwaukee-03-desktop.webp";
import milwaukee04 from "./Site_Projects/07-Milwaukee/Milwaukee-04-desktop.webp";
import milwaukee05 from "./Site_Projects/07-Milwaukee/Milwaukee-05-desktop.webp";
import milwaukee06 from "./Site_Projects/07-Milwaukee/Milwaukee-06-desktop.webp";
import milwaukee07 from "./Site_Projects/07-Milwaukee/Milwaukee-07-desktop.webp";
import milwaukee08 from "./Site_Projects/07-Milwaukee/Milwaukee-08-desktop.webp";

//new city imports
import newCity01 from "./Site_Projects/05-New-City-Moving-Company/New-City-Moving-01-desktop.webp";
import newCity02 from "./Site_Projects/05-New-City-Moving-Company/New-City-Moving-02-desktop.webp";
import newCity03 from "./Site_Projects/05-New-City-Moving-Company/New-City-Moving-03-desktop.webp";
import newCity04 from "./Site_Projects/05-New-City-Moving-Company/New-City-Moving-04-desktop.webp";
import newCity05 from "./Site_Projects/05-New-City-Moving-Company/New-City-Moving-05-desktop.webp";
import newCity06 from "./Site_Projects/05-New-City-Moving-Company/New-City-Moving-06-desktop.webp";
import newCity07 from "./Site_Projects/05-New-City-Moving-Company/New-City-Moving-07-desktop.webp";
import newCity08 from "./Site_Projects/05-New-City-Moving-Company/New-City-Moving-08-desktop.webp";
import newCity09 from "./Site_Projects/05-New-City-Moving-Company/New-City-Moving-09-desktop.webp";
import newCity10 from "./Site_Projects/05-New-City-Moving-Company/New-City-Moving-10-desktop.webp";

//noninas imports
import nonnina01 from "./Site_Projects/17-Nonnina_s/Nonnina-01-desktop.webp";
import nonnina02 from "./Site_Projects/17-Nonnina_s/Nonnina-02-desktop.webp";
import nonnina03 from "./Site_Projects/17-Nonnina_s/Nonnina-03-desktop.webp";
import nonnina04 from "./Site_Projects/17-Nonnina_s/Nonnina-04-desktop.webp";
import nonnina05 from "./Site_Projects/17-Nonnina_s/Nonnina-05-desktop.webp";
import nonnina06 from "./Site_Projects/17-Nonnina_s/Nonnina-06-desktop.webp";
import nonnina07 from "./Site_Projects/17-Nonnina_s/Nonnina-07-desktop.webp";
import nonnina08 from "./Site_Projects/17-Nonnina_s/Nonnina-08-desktop.webp";
import nonnina09 from "./Site_Projects/17-Nonnina_s/Nonnina-09-desktop.webp";
import nonnina10 from "./Site_Projects/17-Nonnina_s/Nonnina-10-desktop.webp";
import nonnina11 from "./Site_Projects/17-Nonnina_s/Nonnina-11-desktop.webp";
import nonnina12 from "./Site_Projects/17-Nonnina_s/Nonnina-12-desktop.webp";
import nonnina13 from "./Site_Projects/17-Nonnina_s/Nonnina-13-desktop.webp";

//Potomac imports
import potomac01 from "./Site_Projects/09-Potomac/Potomac-01-desktop.webp";
import potomac02 from "./Site_Projects/09-Potomac/Potomac-02-desktop.webp";
import potomac03 from "./Site_Projects/09-Potomac/Potomac-03-desktop.webp";
import potomac04 from "./Site_Projects/09-Potomac/Potomac-04-desktop.webp";
import potomac05 from "./Site_Projects/09-Potomac/Potomac-05-desktop.webp";
import potomac06 from "./Site_Projects/09-Potomac/Potomac-06-desktop.webp";
import potomac07 from "./Site_Projects/09-Potomac/Potomac-07-desktop.webp";
import potomac08 from "./Site_Projects/09-Potomac/Potomac-08-desktop.webp";
import potomac09 from "./Site_Projects/09-Potomac/Potomac-09-desktop.webp";
import potomac10 from "./Site_Projects/09-Potomac/Potomac-10-desktop.webp";
import potomac11 from "./Site_Projects/09-Potomac/Potomac-11-desktop.webp";
import potomac12 from "./Site_Projects/09-Potomac/Potomac-12-desktop.webp";
import potomac13 from "./Site_Projects/09-Potomac/Potomac-13-desktop.webp";
import potomac14 from "./Site_Projects/09-Potomac/Potomac-14-desktop.webp";

//Ridge imports
import ridge01 from "./Site_Projects/19-Ridge/Ridge-01-desktop.webp";
import ridge02 from "./Site_Projects/19-Ridge/Ridge-02-desktop.webp";
import ridge03 from "./Site_Projects/19-Ridge/Ridge-03-desktop.webp";
import ridge04 from "./Site_Projects/19-Ridge/Ridge-04-desktop.webp";
import ridge05 from "./Site_Projects/19-Ridge/Ridge-05-desktop.webp";
import ridge06 from "./Site_Projects/19-Ridge/Ridge-06-desktop.webp";
import ridge07 from "./Site_Projects/19-Ridge/Ridge-07-desktop.webp";
import ridge08 from "./Site_Projects/19-Ridge/Ridge-08-desktop.webp";
import ridge09 from "./Site_Projects/19-Ridge/Ridge-09-desktop.webp";
import ridge10 from "./Site_Projects/19-Ridge/Ridge-10-desktop.webp";

//St.Croix imports
import stcroix01 from "./Site_Projects/15-St-Croix-Falls-Public-Library/St-Croix-Falls-01-desktop.webp";
import stcroix02 from "./Site_Projects/15-St-Croix-Falls-Public-Library/St-Croix-Falls-02-desktop.webp";
import stcroix03 from "./Site_Projects/15-St-Croix-Falls-Public-Library/St-Croix-Falls-03-desktop.webp";
import stcroix04 from "./Site_Projects/15-St-Croix-Falls-Public-Library/St-Croix-Falls-04-desktop.webp";
import stcroix05 from "./Site_Projects/15-St-Croix-Falls-Public-Library/St-Croix-Falls-05-desktop.webp";
import stcroix06 from "./Site_Projects/15-St-Croix-Falls-Public-Library/St-Croix-Falls-06-desktop.webp";
import stcroix07 from "./Site_Projects/15-St-Croix-Falls-Public-Library/St-Croix-Falls-07-desktop.webp";
import stcroix08 from "./Site_Projects/15-St-Croix-Falls-Public-Library/St-Croix-Falls-08-desktop.webp";
import stcroix09 from "./Site_Projects/15-St-Croix-Falls-Public-Library/St-Croix-Falls-09-desktop.webp";
import stcroix10 from "./Site_Projects/15-St-Croix-Falls-Public-Library/St-Croix-Falls-10-desktop.webp";
import stcroix11 from "./Site_Projects/15-St-Croix-Falls-Public-Library/St-Croix-Falls-11-desktop.webp";
import stcroix12 from "./Site_Projects/15-St-Croix-Falls-Public-Library/St-Croix-Falls-12-desktop.webp";
import stcroix13 from "./Site_Projects/15-St-Croix-Falls-Public-Library/St-Croix-Falls-13-desktop.webp";
import stcroix14 from "./Site_Projects/15-St-Croix-Falls-Public-Library/St-Croix-Falls-14-desktop.webp";

//Wicker Park imports
import wickerPark01 from "./Site_Projects/13-Wicker-Park/Wicker-01-desktop.webp";
import wickerPark02 from "./Site_Projects/13-Wicker-Park/Wicker-02-desktop.webp";
import wickerPark03 from "./Site_Projects/13-Wicker-Park/Wicker-03-desktop.webp";
import wickerPark04 from "./Site_Projects/13-Wicker-Park/Wicker-04-desktop.webp";
import wickerPark05 from "./Site_Projects/13-Wicker-Park/Wicker-05-desktop.webp";
import wickerPark06 from "./Site_Projects/13-Wicker-Park/Wicker-06-desktop.webp";
import wickerPark07 from "./Site_Projects/13-Wicker-Park/Wicker-07-desktop.webp";
import wickerPark08 from "./Site_Projects/13-Wicker-Park/Wicker-08-desktop.webp";
import wickerPark09 from "./Site_Projects/13-Wicker-Park/Wicker-09-desktop.webp";
import wickerPark10 from "./Site_Projects/13-Wicker-Park/Wicker-10-desktop.webp";
import wickerPark11 from "./Site_Projects/13-Wicker-Park/Wicker-11-desktop.webp";
import wickerPark12 from "./Site_Projects/13-Wicker-Park/Wicker-12-desktop.webp";

//wolcott imports
import wolcott01 from "./Site_Projects/08-Wolcott/Wolcott-01-desktop.webp";
import wolcott02 from "./Site_Projects/08-Wolcott/Wolcott-02-desktop.webp";
import wolcott03 from "./Site_Projects/08-Wolcott/Wolcott-03-desktop.webp";
import wolcott04 from "./Site_Projects/08-Wolcott/Wolcott-04-desktop.webp";
import wolcott05 from "./Site_Projects/08-Wolcott/Wolcott-05-desktop.webp";
import wolcott06 from "./Site_Projects/08-Wolcott/Wolcott-06-desktop.webp";
import wolcott07 from "./Site_Projects/08-Wolcott/Wolcott-07-desktop.webp";
import wolcott08 from "./Site_Projects/08-Wolcott/Wolcott-08-desktop.webp";
import wolcott09 from "./Site_Projects/08-Wolcott/Wolcott-09-desktop.webp";
import wolcott10 from "./Site_Projects/08-Wolcott/Wolcott-10-desktop.webp";
import wolcott11 from "./Site_Projects/08-Wolcott/Wolcott-11-desktop.webp";

// woodLawn I imports
import woodLawn01 from "./Site_Projects/01-Woodlawn/Woodlawn-01-desktop.webp";
import woodLawn02 from "./Site_Projects/01-Woodlawn/Woodlawn-02-desktop.webp";
import woodLawn03 from "./Site_Projects/01-Woodlawn/Woodlawn-03-desktop.webp";
import woodLawn04 from "./Site_Projects/01-Woodlawn/Woodlawn-05-desktop.webp";
import woodLawn05 from "./Site_Projects/01-Woodlawn/Woodlawn-06-desktop.webp";
import woodLawn06 from "./Site_Projects/01-Woodlawn/Woodlawn-07-desktop.webp";
import woodLawn07 from "./Site_Projects/01-Woodlawn/Woodlawn-08-desktop.webp";
import woodLawn08 from "./Site_Projects/01-Woodlawn/Woodlawn-09-desktop.webp";
import woodLawn09 from "./Site_Projects/01-Woodlawn/Woodlawn-10-desktop.webp";
import woodLawn10 from "./Site_Projects/01-Woodlawn/Woodlawn-11-desktop.webp";
import woodLawn11 from "./Site_Projects/01-Woodlawn/Woodlawn-12-desktop.webp";
import woodLawn12 from "./Site_Projects/01-Woodlawn/Woodlawn-13-desktop.webp";
import woodLawn13 from "./Site_Projects/01-Woodlawn/Woodlawn-14-desktop.webp";
import woodLawn14 from "./Site_Projects/01-Woodlawn/Woodlawn-15-desktop.webp";
import woodLawn15 from "./Site_Projects/01-Woodlawn/Woodlawn-16-desktop.webp";
import woodLawn16 from "./Site_Projects/01-Woodlawn/Woodlawn-17-desktop.webp";

//on the boards imports
import ontheBoards01 from "./Site_Projects/22-On-The-Boards/01-Illumina-01-desktop.webp";
import ontheBoards02 from "./Site_Projects/22-On-The-Boards/01-Illumina-02-desktop.webp";
import ontheBoards03 from "./Site_Projects/22-On-The-Boards/01-Illumina-03-desktop.webp";
import ontheBoards04 from "./Site_Projects/22-On-The-Boards/01-Illumina-04-desktop.webp";
import ontheBoards05 from "./Site_Projects/22-On-The-Boards/01-Illumina-05-desktop.webp";
import ontheBoards06 from "./Site_Projects/22-On-The-Boards/01-Illumina-06-desktop.webp";
import ontheBoards07 from "./Site_Projects/22-On-The-Boards/02-Wood-01-desktop.webp";
import ontheBoards08 from "./Site_Projects/22-On-The-Boards/02-Wood-02-desktop.webp";
import ontheBoards09 from "./Site_Projects/22-On-The-Boards/02-Wood-03-desktop.webp";
import ontheBoards10 from "./Site_Projects/22-On-The-Boards/02-Wood-04-desktop.webp";
import ontheBoards11 from "./Site_Projects/22-On-The-Boards/02-Wood-05-desktop.webp";
import ontheBoards12 from "./Site_Projects/22-On-The-Boards/02-Wood-06-desktop.webp";
import ontheBoards13 from "./Site_Projects/22-On-The-Boards/03-Atlantic-01-desktop.webp";
import ontheBoards14 from "./Site_Projects/22-On-The-Boards/03-Atlantic-02-desktop.webp";
import ontheBoards15 from "./Site_Projects/22-On-The-Boards/03-Atlantic-03-desktop.webp";
import ontheBoards16 from "./Site_Projects/22-On-The-Boards/03-Atlantic-04-desktop.webp";
import ontheBoards17 from "./Site_Projects/22-On-The-Boards/03-Atlantic-05-desktop.webp";
import ontheBoards18 from "./Site_Projects/22-On-The-Boards/03-Atlantic-06-desktop.webp";
import ontheBoards19 from "./Site_Projects/22-On-The-Boards/04-Mpower-01-desktop.webp";
import ontheBoards20 from "./Site_Projects/22-On-The-Boards/04-Mpower-02-desktop.webp";
import ontheBoards21 from "./Site_Projects/22-On-The-Boards/04-Mpower-03-desktop.webp";
import ontheBoards22 from "./Site_Projects/22-On-The-Boards/04-Mpower-04-desktop.webp";
import ontheBoards23 from "./Site_Projects/22-On-The-Boards/04-Mpower-05-desktop.webp";
import ontheBoards24 from "./Site_Projects/22-On-The-Boards/04-Mpower-06-desktop.webp";

import construction01 from "./Site_Projects/23-Under-Construction/01-Kimbark-01-desktop.webp";
import construction02 from "./Site_Projects/23-Under-Construction/01-Kimbark-02-desktop.webp";
import construction03 from "./Site_Projects/23-Under-Construction/02-Roscoe-01-desktop.webp";
import construction04 from "./Site_Projects/23-Under-Construction/02-Roscoe-02-desktop.webp";
import construction05 from "./Site_Projects/23-Under-Construction/02-Roscoe-03-desktop.webp";
import construction06 from "./Site_Projects/23-Under-Construction/02-Roscoe-04-desktop.webp";
import construction07 from "./Site_Projects/23-Under-Construction/02-Roscoe-05-desktop.webp";
import construction08 from "./Site_Projects/23-Under-Construction/02-Roscoe-06-desktop.webp";
import construction09 from "./Site_Projects/23-Under-Construction/02-Roscoe-07-desktop.webp";
import construction10 from "./Site_Projects/23-Under-Construction/02-Roscoe-08-desktop.webp";
import construction11 from "./Site_Projects/23-Under-Construction/02-Roscoe-09-desktop.webp";
import construction12 from "./Site_Projects/23-Under-Construction/02-Roscoe-10-desktop.webp";
import construction13 from "./Site_Projects/23-Under-Construction/02-Roscoe-11-desktop.webp";
import construction14 from "./Site_Projects/23-Under-Construction/02-Roscoe-12-desktop.webp";
import construction15 from "./Site_Projects/23-Under-Construction/02-Roscoe-13-desktop.webp";
import construction16 from "./Site_Projects/23-Under-Construction/02-Roscoe-14-desktop.webp";
import construction17 from "./Site_Projects/23-Under-Construction/03-Wood-01-desktop.webp";
import construction18 from "./Site_Projects/23-Under-Construction/03-Wood-02-desktop.webp";
import construction19 from "./Site_Projects/23-Under-Construction/03-Wood-03-desktop.webp";
import construction20 from "./Site_Projects/23-Under-Construction/03-Wood-04-desktop.webp";
import construction21 from "./Site_Projects/23-Under-Construction/03-Wood-05-desktop.webp";
import construction22 from "./Site_Projects/23-Under-Construction/03-Wood-06-desktop.webp";
import construction23 from "./Site_Projects/23-Under-Construction/03-Wood-07-desktop.webp";
import construction24 from "./Site_Projects/23-Under-Construction/03-Wood-08-desktop.webp";
import construction25 from "./Site_Projects/23-Under-Construction/03-Wood-09-desktop.webp";
import construction26 from "./Site_Projects/23-Under-Construction/03-Wood-10-desktop.webp";
import construction27 from "./Site_Projects/23-Under-Construction/03-Wood-11-desktop.webp";
import construction28 from "./Site_Projects/23-Under-Construction/03-Wood-12-desktop.webp";
import construction29 from "./Site_Projects/23-Under-Construction/03-Wood-13-desktop.webp";
import construction30 from "./Site_Projects/23-Under-Construction/03-Wood-14-desktop.webp";
import construction31 from "./Site_Projects/23-Under-Construction/03-Wood-15-desktop.webp";
import construction32 from "./Site_Projects/23-Under-Construction/03-Wood-16-desktop.webp";
import construction33 from "./Site_Projects/23-Under-Construction/03-Wood-17-desktop.webp";
import construction34 from "./Site_Projects/23-Under-Construction/03-Wood-18-desktop.webp";
import construction35 from "./Site_Projects/23-Under-Construction/03-Wood-19-desktop.webp";
import construction36 from "./Site_Projects/23-Under-Construction/03-Wood-20-desktop.webp";
import construction37 from "./Site_Projects/23-Under-Construction/03-Wood-21-desktop.webp";
import construction38 from "./Site_Projects/23-Under-Construction/03-Wood-22-desktop.webp";
import construction39 from "./Site_Projects/23-Under-Construction/04-Woodlawn-01-desktop.webp";
import construction40 from "./Site_Projects/23-Under-Construction/04-Woodlawn-02-desktop.webp";
import construction41 from "./Site_Projects/23-Under-Construction/04-Woodlawn-03-desktop.webp";
import construction42 from "./Site_Projects/23-Under-Construction/04-Woodlawn-04-desktop.webp";
import construction43 from "./Site_Projects/23-Under-Construction/04-Woodlawn-05-desktop.webp";
import construction44 from "./Site_Projects/23-Under-Construction/04-Woodlawn-06-desktop.webp";
import construction45 from "./Site_Projects/23-Under-Construction/05-Marz-01-desktop.webp";

//archive imports
import archive01 from "./Site_Projects/24-Archive/01-Metric-Coffee-01-desktop.webp";
import archive02 from "./Site_Projects/24-Archive/01-Metric-Coffee-02-desktop.webp";
import archive03 from "./Site_Projects/24-Archive/01-Metric-Coffee-03-desktop.webp";
import archive04 from "./Site_Projects/24-Archive/01-Metric-Coffee-04-desktop.webp";
import archive05 from "./Site_Projects/24-Archive/01-Metric-Coffee-05-desktop.webp";
import archive06 from "./Site_Projects/24-Archive/01-Metric-Coffee-06-desktop.webp";
import archive07 from "./Site_Projects/24-Archive/02-Potomac-II-01-desktop.webp";
import archive08 from "./Site_Projects/24-Archive/02-Potomac-II-02-desktop.webp";
import archive09 from "./Site_Projects/24-Archive/02-Potomac-II-03-desktop.webp";
import archive10 from "./Site_Projects/24-Archive/02-Potomac-II-04-desktop.webp";
import archive11 from "./Site_Projects/24-Archive/02-Potomac-II-05-desktop.webp";
import archive12 from "./Site_Projects/24-Archive/02-Potomac-II-06-desktop.webp";
import archive13 from "./Site_Projects/24-Archive/03-Racine-01-desktop.webp";
import archive14 from "./Site_Projects/24-Archive/03-Racine-02-desktop.webp";
import archive15 from "./Site_Projects/24-Archive/03-Racine-03-desktop.webp";
import archive16 from "./Site_Projects/24-Archive/03-Racine-04-desktop.webp";
import archive17 from "./Site_Projects/24-Archive/03-Racine-05-desktop.webp";
import archive18 from "./Site_Projects/24-Archive/03-Racine-06-desktop.webp";
import archive19 from "./Site_Projects/24-Archive/04-Association-House-01-desktop.webp";
import archive20 from "./Site_Projects/24-Archive/04-Association-House-02-desktop.webp";
import archive21 from "./Site_Projects/24-Archive/04-Association-House-03-desktop.webp";
import archive22 from "./Site_Projects/24-Archive/04-Association-House-04-desktop.webp";
import archive23 from "./Site_Projects/24-Archive/04-Association-House-05-desktop.webp";
import archive24 from "./Site_Projects/24-Archive/04-Association-House-06-desktop.webp";
import archive25 from "./Site_Projects/24-Archive/05-Pied-a-Terre-01-desktop.webp";
import archive26 from "./Site_Projects/24-Archive/05-Pied-a-Terre-02-desktop.webp";
import archive27 from "./Site_Projects/24-Archive/05-Pied-a-Terre-03-desktop.webp";
import archive28 from "./Site_Projects/24-Archive/05-Pied-a-Terre-04-desktop.webp";
import archive29 from "./Site_Projects/24-Archive/05-Pied-a-Terre-05-desktop.webp";
import archive30 from "./Site_Projects/24-Archive/05-Pied-a-Terre-06-desktop.webp";
import archive31 from "./Site_Projects/24-Archive/06-688-Milwaukee-01-desktop.webp";
import archive32 from "./Site_Projects/24-Archive/06-688-Milwaukee-02-desktop.webp";
import archive33 from "./Site_Projects/24-Archive/06-688-Milwaukee-03-desktop.webp";
import archive34 from "./Site_Projects/24-Archive/07-Marion-Court-House-01-desktop.webp";
import archive35 from "./Site_Projects/24-Archive/07-Marion-Court-House-02-desktop.webp";
import archive36 from "./Site_Projects/24-Archive/07-Marion-Court-House-03-desktop.webp";
import archive37 from "./Site_Projects/24-Archive/07-Marion-Court-House-04-desktop.webp";
import archive38 from "./Site_Projects/24-Archive/07-Marion-Court-House-05-desktop.webp";
import archive39 from "./Site_Projects/24-Archive/07-Marion-Court-House-06-desktop.webp";
import archive40 from "./Site_Projects/24-Archive/08-CoPro-Radio-Studio-01-desktop.webp";
import archive41 from "./Site_Projects/24-Archive/08-CoPro-Radio-Studio-02-desktop.webp";
import archive42 from "./Site_Projects/24-Archive/08-CoPro-Radio-Studio-03-desktop.webp";
import archive43 from "./Site_Projects/24-Archive/09-School-01-desktop.webp";
import archive44 from "./Site_Projects/24-Archive/09-School-02-desktop.webp";
import archive45 from "./Site_Projects/24-Archive/09-School-03-desktop.webp";
import archive46 from "./Site_Projects/24-Archive/10-Elston-01-desktop.webp";
import archive47 from "./Site_Projects/24-Archive/10-Elston-02-desktop.webp";
import archive48 from "./Site_Projects/24-Archive/10-Elston-03-desktop.webp";
import archive49 from "./Site_Projects/24-Archive/10-Elston-04-desktop.webp";
import archive50 from "./Site_Projects/24-Archive/10-Elston-05-desktop.webp";
import archive51 from "./Site_Projects/24-Archive/10-Elston-06-desktop.webp";
import archive52 from "./Site_Projects/24-Archive/11-Suburban-Farmette-01-desktop.webp";
import archive53 from "./Site_Projects/24-Archive/11-Suburban-Farmette-02-desktop.webp";
import archive54 from "./Site_Projects/24-Archive/11-Suburban-Farmette-03-desktop.webp";
import archive55 from "./Site_Projects/24-Archive/11-Suburban-Farmette-04-desktop.webp";
import archive56 from "./Site_Projects/24-Archive/11-Suburban-Farmette-05-desktop.webp";
import archive57 from "./Site_Projects/24-Archive/11-Suburban-Farmette-06-desktop.webp";
import archive58 from "./Site_Projects/24-Archive/12-Juicy-01-desktop.webp";
import archive59 from "./Site_Projects/24-Archive/12-Juicy-02-desktop.webp";
import archive60 from "./Site_Projects/24-Archive/12-Juicy-03-desktop.webp";
import archive61 from "./Site_Projects/24-Archive/12-Juicy-04-desktop.webp";
import archive62 from "./Site_Projects/24-Archive/12-Juicy-05-desktop.webp";
import archive63 from "./Site_Projects/24-Archive/12-Juicy-06-desktop.webp";
import archive64 from "./Site_Projects/24-Archive/13-Moorman-01-desktop.webp";
import archive65 from "./Site_Projects/24-Archive/13-Moorman-02-desktop.webp";
import archive66 from "./Site_Projects/24-Archive/13-Moorman-03-desktop.webp";
import archive67 from "./Site_Projects/24-Archive/13-Moorman-04-desktop.webp";
import archive68 from "./Site_Projects/24-Archive/13-Moorman-05-desktop.webp";
import archive69 from "./Site_Projects/24-Archive/13-Moorman-06-desktop.webp";
import archive70 from "./Site_Projects/24-Archive/14-Piccolo-Sogno-01-desktop.webp";
import archive71 from "./Site_Projects/24-Archive/14-Piccolo-Sogno-02-desktop.webp";
import archive72 from "./Site_Projects/24-Archive/14-Piccolo-Sogno-03-desktop.webp";
import archive73 from "./Site_Projects/24-Archive/16-Prestige-Showroom-01-desktop.webp";
import archive74 from "./Site_Projects/24-Archive/16-Prestige-Showroom-02-desktop.webp";
import archive75 from "./Site_Projects/24-Archive/16-Prestige-Showroom-03-desktop.webp";
import archive76 from "./Site_Projects/24-Archive/17-Milwaukee-Bike-01-desktop.webp";
import archive77 from "./Site_Projects/24-Archive/17-Milwaukee-Bike-02-desktop.webp";
import archive78 from "./Site_Projects/24-Archive/17-Milwaukee-Bike-03-desktop.webp";
import archive79 from "./Site_Projects/24-Archive/18-Redhook-01-desktop.webp";
import archive80 from "./Site_Projects/24-Archive/18-Redhook-02-desktop.webp";
import archive81 from "./Site_Projects/24-Archive/18-Redhook-03-desktop.webp";
import archive82 from "./Site_Projects/24-Archive/19-Polish-Triangle-01-desktop.webp";
import archive83 from "./Site_Projects/24-Archive/19-Polish-Triangle-03-desktop.webp";
import archive84 from "./Site_Projects/24-Archive/19-PolishTriangle-02-desktop.webp";
import archive85 from "./Site_Projects/24-Archive/20-St-Croix-01-desktop.webp";
import archive86 from "./Site_Projects/24-Archive/20-St-Croix-02-desktop.webp";
import archive87 from "./Site_Projects/24-Archive/20-St-Croix-03-desktop.webp";
import archive88 from "./Site_Projects/24-Archive/21-Hermitage-Garden-01-desktop.webp";
import archive89 from "./Site_Projects/24-Archive/22-Gnome-01-desktop.webp";
import archive90 from "./Site_Projects/24-Archive/22-Gnome-02-desktop.webp";
import archive91 from "./Site_Projects/24-Archive/22-Gnome-03-desktop.webp";

const generateSlug = (title) => {
  return title
    .toLowerCase() // Convert to lowercase
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/[^a-z0-9-]/g, "") // Remove special characters
    .replace(/-+/g, "-") // Remove duplicate hyphens
    .trim(); // Remove leading/trailing spaces
};

export const Collections = [
  {
    type: "On The Boards",
    photos: [
      ontheBoards01,
      ontheBoards02,
      ontheBoards03,
      ontheBoards04,
      ontheBoards05,
      ontheBoards06,
      ontheBoards07,
      ontheBoards08,
      ontheBoards09,
      ontheBoards10,
      ontheBoards11,
      ontheBoards12,
      ontheBoards13,
      ontheBoards14,
      ontheBoards15,
      ontheBoards16,
      ontheBoards17,
      ontheBoards18,
      ontheBoards19,
      ontheBoards20,
      ontheBoards21,
      ontheBoards22,
      ontheBoards23,
      ontheBoards24,
    ],
  },
  {
    type: "Under Construction",
    photos: [
      construction01,
      construction02,
      construction03,
      construction04,
      construction05,
      construction06,
      construction07,
      construction08,
      construction09,
      construction10,
      construction11,
      construction12,
      construction13,
      construction14,
      construction15,
      construction16,
      construction17,
      construction18,
      construction19,
      construction20,
      construction21,
      construction22,
      construction23,
      construction24,
      construction25,
      construction26,
      construction27,
      construction28,
      construction29,
      construction30,
      construction31,
      construction32,
      construction33,
      construction34,
      construction35,
      construction36,
      construction37,
      construction38,
      construction39,
      construction40,
      construction41,
      construction42,
      construction43,
      construction44,
      construction45,
    ],
  },
  {
    type: "Archive",
    photos: [
      archive01,
      archive02,
      archive03,
      archive04,
      archive05,
      archive06,
      archive07,
      archive08,
      archive09,
      archive10,
      archive11,
      archive12,
      archive13,
      archive14,
      archive15,
      archive16,
      archive17,
      archive18,
      archive19,
      archive20,
      archive21,
      archive22,
      archive23,
      archive24,
      archive25,
      archive26,
      archive27,
      archive28,
      archive29,
      archive30,
      archive31,
      archive32,
      archive33,
      archive34,
      archive35,
      archive36,
      archive37,
      archive38,
      archive39,
      archive40,
      archive41,
      archive42,
      archive43,
      archive44,
      archive45,
      archive46,
      archive47,
      archive48,
      archive49,
      archive50,
      archive51,
      archive52,
      archive53,
      archive54,
      archive55,
      archive56,
      archive57,
      archive58,
      archive59,
      archive60,
      archive61,
      archive62,
      archive63,
      archive64,
      archive65,
      archive66,
      archive67,
      archive68,
      archive69,
      archive70,
      archive71,
      archive72,
      archive73,
      archive74,
      archive75,
      archive76,
      archive77,
      archive78,
      archive79,
      archive80,
      archive81,
      archive82,
      archive83,
      archive84,
      archive85,
      archive86,
      archive87,
      archive88,
      archive89,
      archive90,
      archive91,
    ],
  },
].map((collection) => ({
  ...collection,
  slug: generateSlug(collection.type),
}));

export const Projects = [
  {
    coverPhoto: woodLawn01,
    title: "Woodlawn",
    subHeading: "9,400 s.f. Single Family Residence",
    credits: [
      "Structural Engineer – Louis Shell Structures",
      "Landscape Architects – The Outside Design Studio",
    ],
    description: [
      "The adaptive re-use and addition to this existing historical, Georgian-style residence presented a challenge.  How to resolve the 120-year-old historic exterior with a modern interior. At the core of the home is a two-story space that was carved from the building by removing the 2nd floor ballroom. This central, family gathering area includes a hearth, formal living and dining room spaces.  The arch top front façade windows in the space were enhanced by removing the bricked-in half circle tops.  An open central metal and millwork custom stair connects the floors and centers the plan in this two-story space.  A terraced sunroom addition was added to the rear of the home that steps down from the first floor of living to the generous rear yard of this triple-lot home. Large format lift-and-slide windows, skylights and a green roof above stitch the rooms interior to the exterior.  Minimally detailed, white-washed white oak wall paneling and millwork line the first floor.  The building’s exterior was lovingly restored with all new copper sheet metal work, a new slate roof and restored masonry that cement the owner’s desire to make an investment in the historic home that will last another 120 years.",
    ],
    photos: [
      woodLawn01,
      woodLawn02,
      woodLawn03,
      woodLawn04,
      woodLawn05,
      woodLawn06,
      woodLawn07,
      woodLawn08,
      woodLawn09,
      woodLawn10,
      woodLawn11,
      woodLawn12,
      woodLawn13,
      woodLawn14,
      woodLawn15,
      woodLawn16,
    ],
  },
  {
    coverPhoto: division01,
    title: "Division",
    subHeading: "29,000 s.f. Mixed use Residential/ Commercial",
    credits: ["Structural Engineer – Louis Shell Structures"],
    description: [
      "This adaptive re-use and addition to a historic limestone façade structure on Wicker Park’s bustling Division Street boulevard sought to create a contextually scaled and relevant in-fill façade to the existing street wall.  Window openings and floors were scaled to the existing surrounding context, and a sun-shading louvered façade was added to the addition.  The finely detailed louvers on each floor are installed at different angles so the façade can dynamically reveal itself slowly as one approaches and reveals the fiber-cement green colored rainscreen behind, activating the facade.",
    ],
    photos: [
      division01,
      division02,
      division03,
      division04,
      division05,
      division06,
      division07,
      division08,
      division09,
      // division10,
    ],
  },
  {
    coverPhoto: wrigley01,
    title: "Bosworth",
    subHeading: "5,800 s.f. Single Family Residence",
    credits: [
      "Structural Engineer – Louis Shell Structures",
      "Landscape Architects – The Outside Design Studio",
    ],
    description: [
      "This extensive renovation and addition to an existing 120 + year old Victorian era frame home was a near re-construction of the original structure.  The project was driven by the owner’s desire to “re-use” the home they had renovated 25 years prior and have lived in since.  Side additions were added to the wide lot home to create a focal point landscaped courtyard centered on the new custom steel and glass stair and custom millwork throughout.  An attached garage with rooftop garden and Ipe sustainable decking and trellis makes for meaningful outdoor urban space.  Every effort was made to emphasize the connection between interior and exterior landscaped areas including a third-floor sunroom that provides a wintertime sun-filled retreat.",
    ],
    photos: [
      wrigley01,
      wrigley02,
      wrigley03,
      wrigley04,
      wrigley05,
      wrigley06,
      wrigley07,
      wrigley08,
      wrigley09,
      wrigley10,
      wrigley11,
      wrigley12,
      wrigley13,
      wrigley14,
      wrigley15,
      wrigley16,
      wrigley17,
      wrigley18,
      wrigley19,
    ],
  },
  {
    coverPhoto: kimbark01,
    title: "Kimbark",
    subHeading: "5,800 s.f. Single Family Residence",
    credits: [
      "Structural Engineer – Louis Shell Structures",
      "Landscape Architects – The Outside Design Studio",
    ],
    description: [
      "This gut renovation and modern addition to an existing 110-year-old Hyde Park home on a large Chicago lot has been affectionately nick-named by a City of Chicago inspector the “Mullet house”.  All business in the front with a party in the back.  The modern concrete stucco clad addition includes a cantilevered Master Suite extending into the rear yard and a two-story great room that includes a modern 2 story fireplace clad in lime plaster.   Extensive landscaping includes a rear yard pine forest, a hidden fire pit, green roof and a side yard putting green that complete the home.",
    ],
    photos: [
      kimbark01,
      kimbark02,
      kimbark03,
      kimbark04,
      kimbark05,
      kimbark06,
      kimbark07,
      kimbark08,
      kimbark09,
      kimbark10,
      kimbark11,
      kimbark12,
      kimbark13,
    ],
  },
  {
    coverPhoto: newCity01,
    title: "New City Moving Company",
    subHeading: "16,296 s.f. Commercial Warehouse Office Renovation",
    credits: [
      "Structural Engineer – Louis Shell Structures",
      "Landscape Architects – The Outside Design Studio",
    ],
    description: [
      "The adaptive re-use conversion of this existing 2-story steel warehouse facility was completed to house the central office and distributing center to the fastest growing moving company in Chicago.  In the central 2-story office space, integrated planters were carved from the first-floor slab to create Biophilic elements of large-scale tropical plants. An architectural approach that connects occupants to nature by incorporating natural elements into buildings.  Design elements were crafted from re-purposed materials taken from the moving industry including pallet screens and soffits and a box-inspired stadium style employee lounge area.  The companies graphic branding are incorporated into the design with large format murals.",
    ],
    photos: [
      newCity01,
      newCity02,
      newCity03,
      newCity04,
      newCity05,
      newCity06,
      newCity07,
      newCity08,
      newCity09,
      newCity10,
    ],
  },
  {
    coverPhoto: fairbanks01,
    title: "Fairbanks",
    subHeading: ["5,500 s.f. Single Family Penthouse Flat"],
    credits: [
      "Structural Engineer – Louis Shell Structures",
      "MEP engineers – Environmental Systems Design, Inc.",
    ],
    description: [
      "Located in Helmut Jahn’s signature condominium building in the Streeterville neighborhood of Chicago, this penthouse unit was designed around the original owner’s bachelor lifestyle and was recently purchased by Chance the Rapper.  Highly customized black walnut paneling, doors and custom millwork throughout create a common theme in the unit and include a floor to ceiling paneled cigar room.  A wood paneled hallway gallery with LED floor lighting highlight the owner’s extensive art collection.  The entire floor was raised with an air-floor HVAC heating system to eliminate ductwork and distribute heat evenly at the window line with minimal floor diffusers.  The floor is finished with custom terrazzo flooring with laser cut art features of different metals that are integrated into the floor.",
    ],
    photos: [
      fairbanks01,
      fairbanks02,
      fairbanks03,
      fairbanks04,
      fairbanks05,
      fairbanks06,
      fairbanks07,
      fairbanks08,
      fairbanks09,
      fairbanks10,
      fairbanks11,
      fairbanks12,
      fairbanks13,
      // fairbanks14,
    ],
  },
  {
    coverPhoto: milwaukee01,
    title: "Milwaukee",
    subHeading: "5,500 s.f. Single Family Flat",
    credits: ["Structural Engineer – Louis Shell Structures"],
    description: [
      "Norsman was challenged with a thoughtful in-fill addition to an existing frame building in the Historic Milwaukee Avenue Landmark district, it was decided to make the new structure contextually “fit-in” by standing out.  The neighborhood’s historic use as a commercial furniture district presented a context of a wide array of ornate facades, loud signage and neon.  To fit-in, an asymmetrical set of windows in scale with the large format “Chicago-style” windows in the historic district and orange metal panels create a contextually scaled, timeless in-fill composition.  Worthy competition to the historic “red-light” style historic structures.",
    ],
    photos: [
      milwaukee01,
      // milwaukee02,
      milwaukee03,
      milwaukee04,
      milwaukee05,
      milwaukee06,
      milwaukee07,
      milwaukee08,
    ],
    videos: [milwaukee02],
  },
  {
    coverPhoto: wolcott01,
    title: "Wolcott",
    subHeading: "",
    credits: ["Consultants:  Structural Engineer – Louis Shell Structures"],
    description: [],
    photos: [
      wolcott01,
      wolcott02,
      wolcott03,
      wolcott04,
      wolcott05,
      wolcott06,
      wolcott07,
      wolcott08,
      wolcott09,
      wolcott10,
      wolcott11,
    ],
  },
  {
    coverPhoto: potomac01,
    title: "Potomac",
    subHeading: "5,800 s.f. Single Family Residence",
    credits: ["Consultants:  Structural Engineer – Louis Shell Structures"],
    description: [
      "Centering this Wicker Park home is a sunken living room “conversation pit” and an open plan with rooms radiating around this central two-story hearth.  Warm, scandic materials including polished concrete, whitewashed black walnut floors, custom millwork and sloped pine ceilings complement the light-filled interiors.  This wide Chicago lot home utilizes minimally detailed concrete stucco and whitewashed exterior wood cladding.  A heavily landscaped yard including thirteen trees have warmly stitched this home into the neighborhood.",
    ],
    photos: [
      potomac01,
      potomac02,
      potomac03,
      potomac04,
      potomac05,
      potomac06,
      potomac07,
      potomac08,
      potomac09,
      potomac10,
      potomac11,
      potomac12,
      potomac13,
      potomac14,
    ],
  },
  {
    coverPhoto: leavittI01,
    title: "Leavitt I",
    subHeading: "6,500 s.f. Single Family Residence",
    credits: ["Consultants:  Structural Engineer – Louis Shell Structures"],
    description: [
      "A corner courtyard building on a double lot, this adaptive re-use residence transformed a derelict 2-unit apartment with ground floor commercial building into a modern home for live, work, and play.  At the building’s core first level, a maker space and music room were added at opposite ends of the interior court with accordian window walls that completely open to create one massive interior/exterior space for public music performances.  The owner has had multiple concerts since with a guest list of 100 or more. The original 400-year-old “old growth” pine floor joists were harvested and reused for building elements including the central staircase, siding, new structural beams in the music room and a custom exterior fence.  A custom black walnut millwork kitchen was designed to resemble the owner’s (and our) favorite restaurant in Chicago, Lula Café in Logan Square.",
    ],
    photos: [
      leavittI01,
      leavittI02,
      leavittI03,
      leavittI04,
      leavittI05,
      leavittI06,
      leavittI07,
      leavittI08,
      leavittI09,
      leavittI10,
      // leavittI11,
      leavittI12,
      leavittI13,
      leavittI14,
      leavittI15,
      leavittI16,
      leavittI17,
    ],
    videos: [leavittI11],
  },
  {
    coverPhoto: marias01,
    title: "Maria's",
    subHeading: "2,500 s.f. Bar/ Restaurant addition",
    credits: [
      "Structural Engineer – Louis Shell Structures",
      "MEP engineers – Environmental Systems Design, Inc.",
    ],
    description: [
      "This 2500 s.f. addition was intended to be an eccentric addition to the historical, consistently ranked “best dive bar”, Maria’s in the Bridgeport neighborhood of Chicago.  The challenge presented by this addition was to respect this sacred, historic space while discreetly adding a new substantial bar and restaurant addition.  The existing packaged goods store and bar were left virtually untouched with only one new access point to the addition at the rear of the bar in typical speak-easy fashion.  Guests must bathe in the history of the original bar prior to entering the new addition. The entire one-story roof of the addition is warped and rises to the street creating a dynamic street presence.  A skylight above the bar warps and twists to meet the northern sun providing indirect daylighting over the mixologist area.  Simple, inexpensive materials are used in a creative fashion throughout.  The roof tile mosaic is cut from standard plywood.  The sheets are cut in a precise fashion and reassembled in a colorful mosaic. Shou sugi ban burnt wood slats are assembled on the bar front in another mosaic custom pattern. Inexpensive pine boards are stained, cut and assembled on the front façade. Skatelite, a material used for the surfaces of skate parks, is used for table tops and the bar top.  All bar/restaurant furniture was custom designed and fabricated on site of stained birch plywood.",
      "The building opens up to the rear beer garden with 2 large glass garage doors, and to the side yard with two large, hidden pivot walls behind the built-in benches so the owners can host larger events in the neighborhood they affectionately call the “Community of the Future”.",
    ],
    photos: [
      marias01,
      marias02,
      marias03,
      marias04,
      marias05,
      marias06,
      marias07,
      marias08,
      marias09,
      marias10,
      marias11,
    ],
  },
  {
    coverPhoto: marz01,
    title: "Marz Brewing",
    subHeading: "22,000 s.f. Commercial Renovation of Loft Building",
    credits: [
      "Structural Engineer – Louis Shell Structures",
      "MEP engineers – Environmental Systems Design, Inc.",
    ],
    description: [
      "Ideally, larger scale breweriy facilities such as this are located on the ground floor of a building to accommodate the movement of brewing materials into the facility and finished products out of the facility.  Our client presented us with a less-than-ideal existing loft building with a first floor a half floor above grade and a basement level a half level below grade.  Neither floor had the ceiling height to accommodate the new brewing equipment.  By removing a portion of the first-floor loft construction, we were able to locate the brewery in the basement and utilize the existing freight elevator to move products in and out of the facility.  A glass atrium surrounds the new two-story space so the brewing production could be observed from the tasting room/ Brewpub on the first floor.  Material from the removed floor was re-purposed to create bar tables and booths in the tasting room.  Art walls adorn the walls of the Brewpub from artists curated by the owner who happens to own a “counter-culture” contemporary art gallery in this “Back of the Yards” neighborhood.",
    ],
    photos: [
      marz01,
      marz02,
      marz03,
      marz04,
      marz05,
      marz06,
      marz07,
      marz08,
      marz09,
      marz10,
      marz11,
      marz12,
      marz13,
      marz14,
      marz15,
      marz16,
      marz17,
      marz18,
    ],
  },
  {
    coverPhoto: wickerPark01,
    title: "Wicker Park",
    subHeading: "9,600 s.f. Mixed use Condominium Building",
    credits: ["Structural Engineer – Louis Shell Structures"],
    description: [
      "Located on Wicker Park Avenue in the Wicker Park neighborhood of Chicago, Norsman developed, served as architect and builder on the construction of this 4-story new construction building with 3 wide residential flats and a ground floor commercial space.  The flats have German wood burning fireplaces, Italian kitchen cabinets, colorful Bertazzoni ranges, two terraces and 8’ x 20’ “lift and slide” doors on the front bay that open to the historic neighborhood beyond.  The entire building is clad with a corrugated metal rain-screen for a consistent look on the building’s entirety and offer a visible presence from the CTA blue line train tracks.",
    ],
    photos: [
      wickerPark01,
      wickerPark02,
      wickerPark03,
      wickerPark04,
      wickerPark05,
      wickerPark06,
      wickerPark07,
      wickerPark08,
      wickerPark09,
      wickerPark10,
      wickerPark11,
      wickerPark12,
    ],
  },
  {
    coverPhoto: lakem01,
    title: "Lake Meadows",
    subHeading: "Four existing Residential Tower Campus and Amenity Renovation",
    credits: [],
    description: [
      "Norsman was employed to update the common areas and add new amenity spaces to breathe life into this Historic SOM designed Modernist apartment building campus on the South Side of Chicago.  A discreet addition of warm, black walnut CNC panels was added to the classic marble and terrazzo lobbies with a pattern inspired from the snow drifts of Lake Michigan which sits 2 blocks to the East.  A new leasing office was added to one of the buildings and new ground floor club rooms, exercise rooms and bike rooms were added to each building.  Historic photos of the property and its surrounding site were made into wallcoverings to adorn the Leasing office and reflect the campus’s rich history.  Corridor and apartment updates were also made with the amenity improvements, and all have helped to increase the rental income of the property by nearly 40%.",
    ],
    photos: [
      lakem01,
      lakem02,
      lakem03,
      lakem04,
      lakem05,
      lakem06,
      lakem08,
      lakem09,
      lakem10,
    ],
  },
  {
    coverPhoto: stcroix01,
    title: "St. Croix Falls Public Library",
    subHeading: "17,500 s.f. Adaptive Re-use Institutional Building",
    credits: [
      "Structural Engineer - Louis Shell Engineers",
      "MEP Engineers - IBC Engineering",
    ],
    description: [
      "This project entailed the adaptive re-use of a derelict grocery store located at the gateway to the historic downtown of this northern Wisconsin community of just over 2,000 residents.  With the rich Library program, Norsman took design cues from the community's interest in developing a progressive, sustainable vision for the community's future.  The library stacks meander like the adjacent St. Croix river to encourage chance discovery. The public plaza takes cues from the local landscape with varied landscape features including a white pine grove, reading mound, meditation garden and a stormwater riverlet that runs through the plaza to encourage child play and understanding of storm water management.",
      "Local prehistoric volcanic trap rock was included in the concrete mix for the plaza pavement and retaining walls in addition to the polished concrete floors of the Library's interior. Quotes from famous naturalist authors are sandblasted into the public plaza retaining walls to communicate for years to come.  The primary heating source for this cold climate locale is a solar thermal rooftop array that provides primary heat for the building's radiant floors.  Sound absorptive hanging ceiling panels are inversely cut from a topographic survey of the community's river valley.  Additional sustainable Highlights include super insulated roof, solar thermal rooftop array for primary radiant heat, local materials, recycled building, reduced impermeable site, recycled building materials, Solatube skylights and sunlight monitoring lighting controls and low-flow plumbing fixtures.",
    ],
    photos: [
      stcroix01,
      stcroix02,
      stcroix03,
      stcroix04,
      stcroix05,
      stcroix06,
      stcroix07,
      stcroix08,
      stcroix09,
      stcroix10,
      stcroix11,
      stcroix12,
      stcroix13,
      stcroix14,
    ],
  },
  {
    coverPhoto: hermitage01,
    title: "Hermitage",
    subHeading: "4,000 s.f. Speculative Single-Family Residence",
    credits: ["Structural Engineer - Louis Shell Structures"],
    description: [
      "This ground up new construction single family home was developed, designed and built by Norsman.  This infill building is ideally located in the heart of the Bucktown neighborhood of Chicago and immediately south of the 606 Trail, a repurposed elevated rail-line turned park.  An open plan was created with a central light court that opens to a first-floor terrace.  A custom designed and detailed white oak stair serves all 4 floors, and an Italian open kitchen opens to the rear yard.  The entire home is clad in black concrete stucco and whitewashed cedar to give the building a clean, quiet modernist street presence.",
    ],
    photos: [
      hermitage01,
      hermitage02,
      hermitage03,
      hermitage04,
      hermitage05,
      hermitage06,
      hermitage07,
      hermitage08,
      hermitage09,
      hermitage10,
      hermitage11,
      hermitage12,
    ],
  },
  {
    coverPhoto: nonnina01,
    subHeading: "2,500 s.f. Restaurant Renovation",
    credits: [""],
    title: "Nonnina's",
    description: [
      "The owner’s of an existing restaurant employed our services to update and re-brand their existing Italian restaurant to Nonnina’s (Grandma’s in Italian).  Photos of the three owner’s Italian grandmothers were obtained and create a pixelated wallcovering backdrop to the new bar area.  All graphic design including logos, signage and menus were created by Norsman for the new restaurant concept.  The interior design of the space was inspired by classic Italian eateries in NYC and include red-velvet booths, wood lattice ceiling clouds and warm romantic interior finishes.  A private dining room has a neon sign with the words “Che Figata”, Italian slang for “how cool”.",
    ],
    photos: [
      nonnina01,
      nonnina02,
      nonnina03,
      nonnina04,
      nonnina05,
      nonnina06,
      nonnina07,
      nonnina08,
      nonnina09,
      nonnina10,
      nonnina11,
      nonnina12,
      nonnina13,
    ],
  },
  {
    coverPhoto: caffe01,
    title: "Caffe Streets",
    subHeading: "1200 s.f. Commercial Café Design",
    credits: [""],
    description: [
      "Located on Wicker Park’s vibrant Division Street, Caffe Streets balances a “social” coffee house atmosphere with a highly functional Masters Barista bar designed for craft coffee making.  Reinforcing the owner’s belief that the street is a vital component of our urban community, elements of the street are brought into the cafe.   Above the chrome and dark wood of the coffee bar, the baristas’ work is illuminated by retrofitted Chicago streetlights while a wide storefront displays to the street the intimate 1,200 square foot interior space and backlit coffee bar.",
      "The layered marbled bamboo plywood ceiling is inspired by the baristas artistic pouring of steamed milk into coffee. Custom tables and chairs maximize the intimate seating of the café creating an intimate Chicago social third space.",
    ],
    photos: [
      caffe01,
      caffe02,
      caffe03,
      caffe04,
      caffe05,
      caffe06,
      caffe07,
      caffe08,
      caffe09,
      caffe10,
      caffe11,
      caffe12,
    ],
  },
  {
    coverPhoto: ridge01,
    title: "Ridge",
    subHeading: "65,000 s.f. Multi-family Residential Development",
    credits: ["Structural Engineer  - C.E. Anderson Assoc's."],
    description: [
      "This 34-unit residential loft building sits on one of the oldest streets in Evanston at the juncture of an historic landmark residential neighborhood and the edge of the city’s urban center.  The design sets out to ease this juncture by terracing the addition away from residential district with 20 ft. setbacks creating expansive  Additionally, the existing residential tree canopy continues across the building with 24 roof top trees and a continuous balcony covered with a vegetated wall created with Green Screen. The building acts as an architectural green berm to the urban center.",
      "In contrast, the city facing elevation addresses the urban core taking cues from the existing street facade as well as the prehistoric shorelines of Lake Michigan on which it sits.  This city facade floors cantilever over the existing building with an undulating facade that is derived from the city’s historical shorelines.",
      "Sustainable Highlights: Passive sun shading, decreased impervious surfaces, stormwater detention, a ventilated facade and sustainable materials.",
    ],
    photos: [
      ridge01,
      ridge02,
      ridge03,
      ridge04,
      ridge05,
      ridge06,
      ridge07,
      ridge08,
      ridge09,
      ridge10,
    ],
  },
  {
    coverPhoto: leavittII01,
    title: "Leavitt II",
    subHeading: "9,000 s.f. Adaptive Re-use Single Family Residence",
    credits: [
      "Structural Engineer - Louis Shell Structures",
      "Solar - Solar Service, Inc.",
      "Geo-thermal - TDH Mechanical",
    ],
    description: [
      "The adaptive re-use of this mixed-use building arose from the client's desire to build a single-family residence in the city for their growing family.  The building footprint was reduced with a cantilevered master suite to maximize the yard.  With an abundance of cubic feet to work with, two story spaces were carved from the existing building to bring light deep into the building and create an open plan.  The first floor plan radiates radiates around the kitchen core of the home, a gesture to the client's love of entertaining and cooking, and his professional training as a chef.",
      "Sustainable highlights:  Geothermal heating and cooling, solar thermal hot water, radiant floors throughout, recycled building, rooftop rainwater cistern, siding milled from existing buildings salvaged joists.",
    ],
    photos: [
      leavittII01,
      leavittII02,
      leavittII03,
      leavittII04,
      leavittII05,
      leavittII06,
      leavittII07,
      leavittII08,
      leavittII09,
      leavittII10,
      leavittII11,
      leavittII12,
      leavittII13,
      leavittII14,
      leavittII15,
      leavittII16,
    ],
  },
  {
    coverPhoto: hoffman01,
    title: "Hoffman Estates Clubhouse",
    subHeading: "3,400 s.f. New Construction Clubhouse facility",
    credits: [
      "Structural Engineer - Louis Shell Engineers",
      "MEPFP Engineers - The Engineering Studio, Inc.",
      "Landscape Architect - Wolff Landscape Architecture",
    ],
    description: [
      "The client's business model for apartment complex acquisitions is to re-position-tired properties with a dynamic clubhouse, apartments and landscaping.   Norsman has assisted with many of their new clubhouse designs including this clubhouse that was shoe-horned between existing apartment buildings.  The building was sited to maximize circulation, and the building opens up to the entry and pool deck areas with a king-truss framed tilted roof plane. The new clubhouse is fitted with custom components including an exposed structure, polished concrete floors, free form pool, birch bark inspired wall panels, pixilated floral wallpaper and a perforated leaf inspired ceiling cloud over the bar area.",
    ],
    photos: [
      hoffman01,
      hoffman02,
      hoffman03,
      hoffman04,
      hoffman05,
      hoffman06,
      hoffman07,
      hoffman08,
      hoffman09,
      hoffman10,
      hoffman11,
    ],
  },
].map((project) => ({
  ...project,
  slug: generateSlug(project.title),
}));
